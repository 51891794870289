// ==============================|| THEME CONFIG  ||============================== //

const config = {
    defaultPath: '/',
    fontFamily: `'Public Sans', sans-serif`,
    i18n: 'en',
    miniDrawer: false,
    container: true,
    mode: 'light',
    presetColor: 'default',
    themeDirection: 'ltr',
    serverUrl: 'https://wimedecins-rest.way-interactive.com',
    ttsServiceUrl: 'http://10.3.30.12:4000',
    ttsHost: 'beepbx.beevoip.it',
    ttsTestVoice: 'Vocalizer Expressive Audrey Harpo 22kHz',
    reactAppVersion: process.env.REACT_APP_VERSION
};

export default config;
export const drawerWidth = 260;

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';
